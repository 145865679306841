import React from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";

const LoadingChartBackdrop = ({ loadingState = true }) => {
  return (
    <>
      {loadingState ? (
        <>
          <CircularProgress color="inherit" className="connecting-spinner" />
          <span>Loading chart data. This might take several minutes.</span>
        </>
      ) : (
        <span>
          Oops! Something went wrong. Chart data has been lost. Please try again
          after a few minutes.
        </span>
      )}
    </>
  );
};

LoadingChartBackdrop.propTypes = {
  state: PropTypes.string,
};

export default LoadingChartBackdrop;
