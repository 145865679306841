import classNames from "classnames";
import React from "react";
import { Line } from "react-chartjs-2";
import LoadingChartBackdrop from "../../../../components/loading-chart-backdrop";
import { useIsTablet } from "../../../../hooks/common.hooks";
import { borderColor, secondaryColor40 } from "../../../../scss/colors.scss";
import { tooltipsBaseOption } from "../../../../utils/chart-util";
import {
  formatNumber,
  formatNumberWithText,
} from "../../../../utils/numberFormater";

const buildChartOption = (isTablet, chartPosition, reverse, lineChartData) => {
  return {
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },

          stacked: true,
          ticks: {
            min:
              isTablet &&
              !isTablet &&
              lineChartData.labels &&
              lineChartData.labels.length > 0
                ? lineChartData.labels[7]
                : "none",
            maxTicksLimit: isTablet ? 5 : 15,
            autoSkip: false,
            maxRotation: 0,
            minRotation: 0,
            beginAtZero: false,
            source: "auto",
          },
          bounds: "data",
          type: "time",
          time: {
            unit: "month",
          },
          distribution: "linear",
        },
      ],
      yAxes: [
        {
          // gridLines: {
          //   display: false,
          // },
          gridLines: {
            drawOnChartArea: false, // only want the grid lines for one axis to show up
          },
          display: !isTablet,
          stacked: false,
          position: isTablet ? "right" : chartPosition,
          ticks: {
            beginAtZero: true,
            display: true,
            reverse,
            userCallback(value) {
              return formatNumberWithText(value, 1);
            },
          },
        },
      ],
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      ...tooltipsBaseOption,
      mode: "nearest",
      callbacks: {
        label(t, d) {
          const xLabel = d.datasets[t.datasetIndex].label;
          const { yLabel } = t;
          const chartValueWithCommas = formatNumber(Math.abs(yLabel));
          const result = `${xLabel}: ${
            yLabel >= 0
              ? `$${chartValueWithCommas}`
              : `-$${chartValueWithCommas}`
          } `;
          return result.trim();
        },
      },
    },
    hover: {
      mode: "nearest",
      intersect: false,
    },
  };
};

const buildChartLegend = (legendDisplay) => {
  return {
    display: legendDisplay,
    position: "bottom",
    fullWidth: true,
    reverse: false,
    labels: {
      usePointStyle: true,
      boxWidth: 200,
    },
  };
};

const buildChartData = (data, estimateProperty, chartColor) => {
  const reduceArray = data.map((item) => {
    const { y = 0 } = item;
    return { ...item, y: (y * 80) / 100 };
  });
  const buyingGoal = data.map((item) => {
    return { ...item, y: estimateProperty };
  });
  return {
    datasets: [
      // These two will be in the same stack.
      {
        label: "Stretching it",
        fill: false,
        backgroundColor: borderColor,
        borderColor: chartColor?.primaryColor || "#04f73d",
        borderCapStyle: "butt",
        borderDash: [5, 10],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBackgroundColor: chartColor?.primaryColor || "#04f73d",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: chartColor?.secondaryColor || "#162029",
        pointHoverBorderColor: secondaryColor40,
        pointHoverBorderWidth: 8,
        borderWidth: 1,
        pointRadius: 0,
        pointBorderColor: chartColor?.primaryColor || "#04f73d",
        pointHitRadius: 3,
        pointStyle: "dash",
        data,
      },
      {
        label: "Buying Goal",
        fill: false,
        backgroundColor: borderColor,
        borderColor: chartColor?.secondaryColor || "#162029",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: chartColor?.secondaryColor || "#162029",
        pointBackgroundColor: chartColor?.primaryColor || "#04f73d",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: chartColor?.secondaryColor || "#162029",
        pointHoverBorderColor: secondaryColor40,
        pointHoverBorderWidth: 8,
        borderWidth: 1,
        pointRadius: 0,
        pointHitRadius: 3,
        data: buyingGoal,
        pointStyle: "line",
      },
      {
        label: "Comfortably Afford",
        fill: false,
        backgroundColor: borderColor,
        borderColor: chartColor?.primaryColor || "#04f73d",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: chartColor?.primaryColor || "#04f73d",
        pointBackgroundColor: chartColor?.primaryColor || "#04f73d",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: chartColor?.secondaryColor || "#162029",
        pointHoverBorderColor: secondaryColor40,
        pointHoverBorderWidth: 8,
        pointRadius: 0,
        borderWidth: 2,
        pointHitRadius: 3,
        data: reduceArray,
        pointStyle: "line",
      },
    ],
  };
};

const ScenarioLineChartComponent = ({
  data = [],
  loadingState = false,
  estimateProperty = 0,
}) => {
  const chartPosition = "left";
  const legendDisplay = true;
  const chartTitle = "";
  const reverse = false;
  const isTablet = useIsTablet();
  const chartOption = buildChartOption(isTablet, chartPosition, reverse, data);
  const chartLegend = buildChartLegend(legendDisplay);
  const chartData = buildChartData(data, estimateProperty);
  return (
    <div className="line-chart-container da-property-bar-chart-container">
      {chartTitle !== "" && (
        <div className="line-chart-title">{chartTitle}</div>
      )}
      <div className="line-chart-size">
        <Line
          data={chartData}
          options={chartOption}
          legend={chartLegend}
          height={null}
          width={null}
        />
        <div
          className={classNames("loading-chart-indicator", {
            "d-none": !loadingState,
          })}
        >
          <LoadingChartBackdrop loadingState={loadingState} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(ScenarioLineChartComponent);
